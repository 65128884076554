import React from 'react'

interface FormGroupProps {
  label?: string
  inputId: string
  children: JSX.Element
  errorMessage?: string
  description?: string
}

const InputGroup = ({
  label,
  inputId,
  children,
  errorMessage,
  description,
}: FormGroupProps): JSX.Element => {
  return (
    <div className="flex flex-col mt-2 mb-8">
      <div className="flex-1">
        {label && <label htmlFor={inputId}>{label}</label>}
        {description && <p className="text-xs">{description}</p>}
      </div>
      <div className="flex-1 pt-1">{children}</div>
      <div className="flex-1">
        {errorMessage && (
          <span className="text-red-600 text-xs">{errorMessage}</span>
        )}
      </div>
    </div>
  )
}

export default InputGroup
