import React from 'react'
import { FormInput } from './FormInterfaces'

type NumberInputProps = {
  placeholder?: string
} & FormInput<number>

const NumberInput = ({
  id,
  onChange = (): void => null,
  placeholder,
  required,
}: NumberInputProps): JSX.Element => {
  return (
    <input
      type="number"
      min={0}
      inputMode="numeric"
      pattern="[0-9]*"
      id={id}
      onChange={onChange}
      placeholder={placeholder}
      className="border border-solid border-gray-300 rounded p-2 w-full placeholder-gray-600 appearance-none"
      required={required}
    />
  )
}

export default NumberInput
