import * as React from 'react'

import SEO from '../components/seo'
import Hero from '../components/landing/Hero'
import Features from '../components/landing/featuresSection/Features'
import LearnMore from '../components/landing/learnMoreSection/LearnMore'
import LayoutWithHeaderAndFooter from '../components/layouts/LayoutWithHeaderAndFooter'
import SubscribeSection from '../components/landing/SubscribeSection'
import ScienceContribution from '../components/landing/ScienceContribution'
import AddLine from '../components/landing/AddLine'

const IndexPage: React.FunctionComponent = () => (
  <div>
    <SEO title="Home" />
    <LayoutWithHeaderAndFooter>
      <div className="flex flex-col space-y-24 lg:space-y-64 mb-32">
        <div>
          <Hero />
          <Features />
        </div>
        <ScienceContribution />
        <LearnMore />
        <AddLine />
        <SubscribeSection />
      </div>
    </LayoutWithHeaderAndFooter>
  </div>
)

export default IndexPage
