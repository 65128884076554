import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import LearnMoreItem from './LearnMoreItem'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../../../constants/i18n'
import { DisplayLarge } from '../../typography'

const LearnMore = (): JSX.Element => {
  const quriedImages =
    useStaticQuery(
      graphql`
        query {
          howTo: file(relativePath: { eq: "learn_more/how_to_handwash.png" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          cleaningSmartphone: file(
            relativePath: { eq: "learn_more/cleaning_smartphone.jpg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          cdc: file(relativePath: { eq: "learn_more/cdc-handwashing.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          handWashing: file(
            relativePath: { eq: "learn_more/hand_washing.jpg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          germsdefence: file(
            relativePath: { eq: "learn_more/germsdefence.jpg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `,
    ) || {}

  const { t } = useTranslation(Namespace.Landing)

  return (
    <section className="lg:px-24">
      <DisplayLarge as="h2" className="mb-20 lg:text-center">
        {t('learnMore.heading')}
      </DisplayLarge>
      {Object.keys(quriedImages).length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6">
          <LearnMoreItem
            image={quriedImages.howTo.childImageSharp}
            title={t('learnMore.item.howTo.title')}
            source={t('learnMore.item.howTo.source')}
            link="https://www.who.int/gpsc/clean_hands_protection/en/"
          />
          <LearnMoreItem
            image={quriedImages.cleaningSmartphone.childImageSharp}
            title={t('learnMore.item.cleaningSmartphone.title')}
            source={t('learnMore.item.cleaningSmartphone.source')}
            link="https://www.bbc.com/news/av/technology-51863924/coronavirus-how-to-clean-your-smartphone-safely"
          />
          <LearnMoreItem
            image={quriedImages.handWashing.childImageSharp}
            title={t('learnMore.item.handWashing.title')}
            source={t('learnMore.item.howTo.source')}
            link="https://www.cdc.gov/handwashing/index.html"
          />
          <LearnMoreItem
            image={quriedImages.cdc.childImageSharp}
            title={t('learnMore.item.cdc.title')}
            source={t('learnMore.item.cdc.source')}
            link="https://www.cdc.gov/handwashing/when-how-handwashing.html"
          />
          <LearnMoreItem
            image={quriedImages.germsdefence.childImageSharp}
            title={t('learnMore.item.germsdefence.title')}
            source={t('learnMore.item.germsdefence.source')}
            link="https://www.germdefence.org"
          />
        </div>
      )}
    </section>
  )
}

export default LearnMore
