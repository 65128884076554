import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import FeatureItem from './FeatureItem'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../../../constants/i18n'
import { DisplayLarge, ParagraphLarge } from '../../typography'

interface FeaturesProps {
  className?: string
}

const Features = ({ className = '' }: FeaturesProps): JSX.Element => {
  const queriedImages =
    useStaticQuery(
      graphql`
        query {
          logging: file(relativePath: { eq: "feature-logging.png" }) {
            childImageSharp {
              fluid(maxWidth: 470, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          summary: file(relativePath: { eq: "feature-summary.png" }) {
            childImageSharp {
              fluid(maxWidth: 470, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tips: file(relativePath: { eq: "feature-tips.png" }) {
            childImageSharp {
              fluid(maxWidth: 470, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          realtimeSummary: file(
            relativePath: { eq: "feature-summary-realtime.png" }
          ) {
            childImageSharp {
              fixed(width: 32) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          weeklySummary: file(
            relativePath: { eq: "feature-summary-weekly.png" }
          ) {
            childImageSharp {
              fixed(width: 32) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `,
    ) || {}

  const { t } = useTranslation(Namespace.Landing)

  return (
    <section className={className}>
      <DisplayLarge as="h2" className="max-w-2xl mx-auto mb-8 lg:text-center">
        {t('features.heading')}
      </DisplayLarge>
      <ParagraphLarge className="max-w-4xl mx-auto mb-16 lg:mb-40 lg:text-center">
        {t('features.subtitle')}
      </ParagraphLarge>
      {Object.keys(queriedImages).length > 0 && (
        <div className="grid grid-flow-row gap-20">
          <FeatureItem
            title={t('features.item.logging.title')}
            text={t('features.item.logging.text')}
            image={queriedImages.logging.childImageSharp}
          />
          <FeatureItem
            title={t('features.item.summary.title')}
            text={t('features.item.summary.text')}
            image={queriedImages.summary.childImageSharp}
            className="md:flex-row-reverse"
          >
            <div className="flex flex-col mt-6 space-y-3">
              <div className="flex flex-row space-x-2">
                <Img
                  fixed={queriedImages.realtimeSummary.childImageSharp.fixed}
                />
                <p className="my-auto font-bold">
                  {t('features.item.summary.realtime')}
                </p>
              </div>
              <div className="flex flex-row space-x-2">
                <Img
                  fixed={queriedImages.weeklySummary.childImageSharp.fixed}
                />
                <p className="my-auto font-bold">
                  {t('features.item.summary.weekly')}
                </p>
              </div>
            </div>
          </FeatureItem>
          <FeatureItem
            title={t('features.item.tips.title')}
            text={t('features.item.tips.text')}
            image={queriedImages.tips.childImageSharp}
          />
        </div>
      )}
    </section>
  )
}
export default Features
