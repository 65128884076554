import React from 'react'
import Img, { GatsbyImageProps } from 'gatsby-image'

interface LearnMoreItemProps {
  title?: string
  image?: GatsbyImageProps
  className?: string
  link?: string
  source?: string
}

const LearnMoreItem = ({
  title,
  image,
  className,
  link,
  source,
}: LearnMoreItemProps): JSX.Element => (
  <a
    className={`group flex flex-1 flex-row space-x-3 md:space-x-0 md:flex-col ${className}`}
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className="flex flex-col md:flex-row justify-center w-20 h-16 md:w-full md:h-40 rounded overflow-hidden bg-blue-700">
      {image && (
        <Img
          fluid={image.fluid}
          className="object-cover h-full w-full group-hover:opacity-75"
        />
      )}
    </div>
    <div className="flex-1 flex flex-col md:py-2 group-hover:bg-blue-600">
      <p className="text-gray-600 text-xs group-hover:text-blue-600">
        {source}
      </p>
      <p className="text-blue-900 font-bold group-hover:underline">{title}</p>
    </div>
  </a>
)

export default LearnMoreItem
