import React from 'react'
import { FormInput } from './FormInterfaces'

type CheckboxProps = {
  label?: string
} & FormInput<boolean>

const Checkbox = ({
  id,
  value = false,
  onChange = (): void => null,
  label,
}: CheckboxProps): JSX.Element => {
  return (
    <div className="flex flex-row">
      <div className="pr-2">
        <input
          type="checkbox"
          name={id}
          id={id}
          checked={value}
          onChange={onChange}
        />
      </div>
      <div className="flex">
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  )
}

export default Checkbox
