import { InputOption } from '../components/RegistrationForm/formComponents/FormInterfaces'
import countryList from './raw/countries.json'

export enum BOOLEAN_VALUE {
  YES = 'yes',
  NO = 'no',
}

export const COUNTRY = countryList

export const GENDER: InputOption<string>[] = [
  { value: 'male', text: 'genderOptions.male' },
  { value: 'female', text: 'genderOptions.female' },
  { value: 'not_specify', text: 'genderOptions.notSpecify' },
  { value: 'other', text: 'genderOptions.other' },
]

export const POSITION: InputOption<string>[] = [
  { value: 'work', text: 'positionOptions.work' },
  { value: 'college', text: 'positionOptions.college' },
  { value: 'school', text: 'positionOptions.school' },
  { value: 'retired', text: 'positionOptions.retired' },
  { value: 'unemployed', text: 'positionOptions.unemployed' },
]

export const BOOLEAN: InputOption<string>[] = [
  { value: BOOLEAN_VALUE.YES, text: 'booleanOptions.yes' },
  { value: BOOLEAN_VALUE.NO, text: 'booleanOptions.no' },
]

export const PLATFORM: InputOption<string>[] = [
  { value: 'facebook_messenger', text: 'Facebook Messenger' },
  { value: 'whatsapp', text: 'WhatsApp' },
  { value: 'google_chat', text: 'Google Chat' },
  { value: 'alexa', text: 'Alexa' },
]

export const MEDICAL_CONDITIONS: InputOption<string>[] = [
  {
    value: 'chronic_lung',
    text: 'medicalConditionOptions.chronicLung',
  },
  {
    value: 'autoimmune',
    text: 'medicalConditionOptions.autoimmune',
  },
  {
    value: 'metabolic',
    text: 'medicalConditionOptions.metabolic',
  },
  {
    value: 'cardiovascular',
    text: 'medicalConditionOptions.cardiovascular',
  },
  {
    value: 'other',
    text: 'medicalConditionOptions.other',
  },
]
