import React, { Fragment } from 'react'
import { FormInputWithOptions } from './FormInterfaces'

type RadioButtonGroupProps = {
  columns?: number
  columnsOnDesktop?: number
  buttonClassName?: string
  buttonClassNameOnActive?: string
} & FormInputWithOptions<string | number>

const RadioButtonGroup = ({
  id,
  options = [],
  value = null,
  onChange = (): void => null,
  required,
  columns = 2,
  columnsOnDesktop,
  buttonClassName = 'border-gray-300 text-gray-600',
  buttonClassNameOnActive = 'border-gray-800',
}: RadioButtonGroupProps): JSX.Element => {
  return (
    <div
      role="radiogroup"
      className={`grid grid-cols-${columns} md:grid-cols-${
        columnsOnDesktop || columns
      } gap-3 mt-1`}
    >
      {options.map((option) => {
        const radioButtonId = `${id}-${option.value}`

        return (
          <Fragment key={option.value}>
            <input
              type="radio"
              name={id}
              value={option.value}
              id={radioButtonId}
              onChange={onChange}
              checked={value === option.value}
              className="hidden"
              required={required}
            />
            <label
              htmlFor={radioButtonId}
              className={`border border-solid rounded p-2 text-center flex flex-col justify-center cursor-pointer transition-colors duration-200 ${
                value === option.value
                  ? buttonClassNameOnActive
                  : buttonClassName
              }`}
            >
              {option.text}
            </label>
          </Fragment>
        )
      })}
    </div>
  )
}

export default RadioButtonGroup
