import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { DisplayLarge, ParagraphLarge } from '../typography'
import { useI18next, Link } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../../constants/i18n'

const StyledLink = ({ children, to }: { children: string; to: string }) => (
  <Link to={to} className="text-blue-600">
    <span className="hover:underline">{children}</span> &#8250;
  </Link>
)

function ScienceContribution(): JSX.Element {
  const { scienceImage } =
    useStaticQuery(
      graphql`
        query {
          scienceImage: file(relativePath: { eq: "science-contribution.png" }) {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `,
    ) || {}

  const { t } = useI18next(Namespace.Landing)

  return (
    <div className="-mx-6 px-6 sm:-mx-12 sm:px-12 lg:-mx-16 md:rounded-lg bg-gray-200 py-16">
      <div className="max-w-4xl mx-auto flex flex-row">
        <div className="flex-1 flex flex-col justify-center">
          <DisplayLarge as="h2" className="mb-8">
            {t('science.heading')}
          </DisplayLarge>
          <ParagraphLarge className="mb-12">
            {t('science.description')}
          </ParagraphLarge>
          <StyledLink to="contribution">
            {t('science.linkText.contribution')}
          </StyledLink>
          <StyledLink to="terms-conditions">
            {t('science.linkText.termsAndConditions')}
          </StyledLink>
        </div>
        <div className="hidden md:block md:pl-8 pl-16 m-auto w-48 lg:w-64">
          {scienceImage && <Img fluid={scienceImage.childImageSharp.fluid} />}
        </div>
      </div>
    </div>
  )
}

export default ScienceContribution
