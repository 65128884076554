import React from 'react'
import { FormInputWithOptions } from './FormInterfaces'

type DropdownProps = {
  placeholder?: string
  defaultValue?: string
} & FormInputWithOptions<string>

const Dropdown = ({
  id,
  options = [],
  onChange = (): void => null,
  placeholder,
  value,
  required,
  defaultValue = '',
}: DropdownProps): JSX.Element => (
  <div className="relative">
    <select
      id={id}
      onBlur={onChange}
      className={`bg-white border border-solid border-gray-300 rounded p-2 w-full appearance-none ${
        value === '' ? 'text-gray-600' : ''
      }`}
      defaultValue={defaultValue}
      required={required}
    >
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map(({ value, text }) => (
        <option value={value} key={value}>
          {text}
        </option>
      ))}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-600">
      <svg
        className="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
)

export default Dropdown
