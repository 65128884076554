import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function LineQRCode(props): JSX.Element {
  const { site, qrCodeStaging, qrCodeProduction } =
    useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              environment
            }
          }
          qrCodeStaging: file(relativePath: { eq: "line-qr-staging.png" }) {
            childImageSharp {
              fixed(width: 160) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          qrCodeProduction: file(
            relativePath: { eq: "line-qr-production.png" }
          ) {
            childImageSharp {
              fixed(width: 160) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `,
    ) || {}

  if (!site) {
    return null
  }

  const qrCode =
    site.siteMetadata.environment === 'production'
      ? qrCodeProduction
      : qrCodeStaging

  return <Img fixed={qrCode.childImageSharp.fixed} {...props} />
}

export default LineQRCode
