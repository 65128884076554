import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../../constants/i18n'
import {
  DisplayXLarge,
  DisplayXSmall,
  ParagraphLarge,
  LabelSmall,
  LabelMedium,
} from '../typography'
import LineQRCode from './LineQRCode'
import LineButton from './LineButton'

interface HeroProps {
  className?: string
}

const Hero = ({ className = '' }: HeroProps): JSX.Element => {
  const { heroScreenshot } =
    useStaticQuery(
      graphql`
        query {
          heroScreenshot: file(
            relativePath: { eq: "hero-screenshot-frame.png" }
          ) {
            childImageSharp {
              fluid(maxHeight: 1209, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `,
    ) || {}

  const { t } = useTranslation(Namespace.Landing)

  return (
    <section className={`flex flex-col md:flex-row` + className}>
      <div className="flex flex-1 flex-col space-y-6 lg:space-y-12 z-10">
        <div className="lg:mt-10">
          <DisplayXSmall className="text-system-pink mb-1">
            {t('hero.tagline')}
          </DisplayXSmall>
          <DisplayXLarge as="h1" className="mb-6">
            {t('hero.title')}
          </DisplayXLarge>
          <ParagraphLarge>{t('hero.subtitle')}</ParagraphLarge>
        </div>
        <div className="hidden lg:flex flex-row space-x-8">
          <div>
            <LineQRCode />
          </div>
          <div>
            <LabelMedium className="mt-4">{t('hero.scanQR')}</LabelMedium>
            <LabelSmall className="mt-1">
              {t('hero.agreement.text')}{' '}
              <Link
                to="/terms-conditions"
                className="text-blue-600 hover:underline"
              >
                {t('hero.agreement.linkLabel')}
              </Link>
            </LabelSmall>
          </div>
        </div>
        <div className="flex lg:hidden">
          <LineButton />
        </div>
      </div>
      <div className="-ml-32 -mr-12 md:-m-20 md:w-full md:max-w-lg lg:max-w-2xl">
        {heroScreenshot && (
          <Img fluid={heroScreenshot.childImageSharp.fluid} className="z-0" />
        )}
      </div>
    </section>
  )
}

export default Hero
