export enum ApiService {
  feedback = 'feedback',
  subscribe = 'subscribe',
  message = 'message',
}

const API_ENDPOINT = process.env.GATSBY_API_ENDPOINT
const responseIsNotOk = (response: Response): boolean => !response.ok

export const callApi = async ({
  service,
  body,
  throwCondition = responseIsNotOk,
}: {
  service: ApiService
  body: Record<string, any>
  throwCondition?: (response: Response) => boolean
}): Promise<Response> => {
  const response = await fetch(`${API_ENDPOINT}/api/${service}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  if (throwCondition(response)) {
    const text = await response.text()
    throw new Error(text)
  }

  return response
}
