import React from 'react'
import Img, { GatsbyImageProps } from 'gatsby-image'
import { DisplayMedium, ParagraphLarge } from '../../typography'

interface FeatureItemProps {
  title: string
  text: string
  image: GatsbyImageProps
  className?: string
  children?: JSX.Element
}

const FeatureItem = ({
  title,
  text,
  image,
  className = '',
  children,
}: FeatureItemProps): JSX.Element => (
  <div
    className={`flex flex-col-reverse md:flex-row justify-between ${className}`}
  >
    <div className="w-full md:w-1/2 -mx-12">
      {image && <Img fluid={image.fluid} />}
    </div>
    <div className="flex-1 md:py-16 max-w-lg">
      <DisplayMedium as="h3" className="mb-8">
        {title}
      </DisplayMedium>
      <ParagraphLarge>{text}</ParagraphLarge>
      {children}
    </div>
  </div>
)

export default FeatureItem
