import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../../constants/i18n'

function LineButton(): JSX.Element {
  const { site } =
    useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              line {
                directLink
              }
            }
          }
        }
      `,
    ) || {}

  const { t } = useI18next(Namespace.Landing)

  if (!site) {
    return null
  }

  return (
    <a
      className="bg-blue-500 rounded-lg w-full text-center text-white text-lg p-3"
      href={site.siteMetadata.line.directLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('hero.startNow')}
    </a>
  )
}

export default LineButton
