import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  InputGroup,
  RadioButtonGroup,
} from '../RegistrationForm/formComponents'
import { TextInput } from '../forms'
import { PLATFORM } from '../../constants/inputOptions'
import { callApi, ApiService } from '../../utils/api'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../../constants/i18n'
import { DisplayLarge, ParagraphLarge } from '../typography'

interface SubscribeData {
  email: string
  platform?: string
}

function submitSubcriber(data: SubscribeData): Promise<Response> {
  return callApi({
    service: ApiService.subscribe,
    body: data,
  })
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address'),
})

const SubscribeSection = (): JSX.Element => {
  const initialFormValue: SubscribeData = {
    email: '',
    platform: null,
  }

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [responseError, setResponseError] = useState(null)

  const { t } = useTranslation(Namespace.Landing)

  return (
    <section>
      <Formik
        initialValues={initialFormValue}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }): Promise<void> => {
          setSubmitting(true)
          let response: Response

          try {
            response = await submitSubcriber(values)
          } catch (error) {
            console.error(error)
            alert('Sorry something went wrong, please try again later')
          }

          const { error } = await response.json()

          if (error) {
            setResponseError(error)
          } else {
            setResponseError(null)
            setIsSubmitted(true)
          }

          setSubmitting(false)
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          errors,
        }): JSX.Element => (
          <form
            onSubmit={handleSubmit}
            noValidate
            className="max-w-3xl mx-auto"
          >
            <DisplayLarge as="h2" className="mb-4">
              {t('subscription.heading')}
            </DisplayLarge>

            <ParagraphLarge className="mb-8">
              {t('subscription.description')}
            </ParagraphLarge>

            <InputGroup inputId="platform" label={t('subscription.platform')}>
              <RadioButtonGroup
                id="platform"
                options={PLATFORM}
                value={values.platform}
                onChange={handleChange}
                required
                columns={2}
                columnsOnDesktop={4}
                buttonClassName="border-gray-300 hover:border-gray-500 text-gray-600"
                buttonClassNameOnActive="border-gray-800"
              />
            </InputGroup>

            <div className="flex flex-col md:flex-row text-left md:space-x-4">
              <div className="flex-1 relative">
                <TextInput
                  id="email"
                  name="email"
                  type="email"
                  label={t('subscription.email')}
                  placeholder={t('subscription.emailPlaceholder')}
                  showError={!isSubmitted && !!errors.email}
                  onFocus={() => setResponseError(null)}
                />
                <div className="absolute inset-x-0 bottom-0 pb-4">
                  {responseError && (
                    <span className="text-red-600">{responseError}</span>
                  )}
                  {isSubmitted && (
                    <span className="text-green-600">
                      {t('subscription.success')}
                    </span>
                  )}
                </div>
              </div>
              <div className="lg:pt-6 text-right">
                <button
                  className="bg-gray-200 rounded font-bold px-6 py-2 hover:bg-gray-300 active:bg-gray-400"
                  disabled={
                    isSubmitted ||
                    isSubmitting ||
                    values.email.length === 0 ||
                    Object.keys(errors).length > 0
                  }
                >
                  {t('subscription.submit')}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </section>
  )
}

export default SubscribeSection
