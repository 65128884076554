import React from 'react'
import { useI18next, Link } from 'gatsby-plugin-react-i18next'
import { DisplayLarge, ParagraphLarge } from '../typography'
import { Namespace } from '../../constants/i18n'
import LineQRCode from './LineQRCode'
import LineButton from './LineButton'

function AddLine(): JSX.Element {
  const { t } = useI18next(Namespace.Landing)

  const AgreementText = () => (
    <p className="text-xs text-gray-400">
      {t('hero.agreement.text')}{' '}
      <Link to="/terms-conditions" className="text-blue-600 hover:underline">
        {t('hero.agreement.linkLabel')}
      </Link>
    </p>
  )

  return (
    <div className="px-6 lg:-mx-16 md:rounded-lg text-white rounded-lg shadow-xl bg-dark-blue py-16">
      <div className="max-w-4xl mx-auto flex flex-col lg:flex-row">
        <div className="flex-1 flex">
          <div className="my-auto">
            <DisplayLarge as="h3" className="mb-8">
              {t('addLine.heading')}
            </DisplayLarge>
            <ParagraphLarge className="text-gray-400">
              {t('addLine.description')}
            </ParagraphLarge>
          </div>
        </div>
        <div className="hidden lg:flex flex-col text-center">
          <LineQRCode className="mx-auto mb-4" />
          <p className="font-bold">{t('hero.scanQR')}</p>
          <AgreementText />
        </div>
        <div className="flex flex-col lg:hidden space-y-2 mt-8">
          <LineButton />
          <AgreementText />
        </div>
      </div>
    </div>
  )
}

export default AddLine
